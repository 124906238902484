import React from 'react'
import './index.css'
import reactImage from '../../assets/react.png'
import nodeImage from '../../assets/node.png'
import jsImage from '../../assets/js.png'
import pyImage from '../../assets/python.png'
import dbImage from '../../assets/database.png'
import htmlImage from '../../assets/html.png'
import cssImage from '../../assets/css.png'
import bootImage from '../../assets/bootstrap.png'
import githubImage from '../../assets/github.png'
import liderImage from '../../assets/leadership.png'
import comunicaImage from '../../assets/message.png'
import proatividadeImage from '../../assets/proactive.png'
import criatividadeImage from '../../assets/brain.png'
import organizaImage from '../../assets/organization.png'
import equipeImage from '../../assets/team.png'
import adaptoImage from '../../assets/adapt.png'
import responsaImage from '../../assets/responsibility.png'
import analiImage from '../../assets/analytical.png'

export default function Skills() {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='skills'>
            <h1 className='skills-tittle'>Skills</h1>
          </div>
          <div className='hard'>
            <ul className='skills-grid'>
              <li className='li-skills'>
                <h2 className='skills-name'>React</h2>
                <img className='img-skills' src={reactImage} alt='React image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Node</h2>
                <img className='img-skills' src={nodeImage} alt='Node image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Javascript</h2>
                <img className='img-skills' src={jsImage} alt='Javascript image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Python</h2>
                <img className='img-skills' src={pyImage} alt='Python image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Data Base</h2>
                <img className='img-skills' src={dbImage} alt='Data base image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>HTML</h2>
                <img className='img-skills' src={htmlImage} alt='HTML image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>CSS</h2>
                <img className='img-skills' src={cssImage} alt='CSS image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Bootstrap</h2>
                <img className='img-skills' src={bootImage} alt='Bootstrap image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Github</h2>
                <img className='img-skills' src={githubImage} alt='Github image' />
              </li>
            </ul>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='skills'>
            <h1 className='skills-tittle'>Soft Skills</h1>
          </div>
          <div className='soft'>
            <ul className='skills-grid'>
              <li className='li-skills'>
                <h2 className='skills-name'>Liderança</h2>
                <img className='img-skills' src={liderImage} alt='Liderança image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Comunicação</h2>
                <img className='img-skills' src={comunicaImage} alt='Comunicação image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Proatividade</h2>
                <img className='img-skills' src={proatividadeImage} alt='Proatividade image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Criatividade</h2>
                <img className='img-skills' src={criatividadeImage} alt='Criatividade image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Organização</h2>
                <img className='img-skills' src={organizaImage} alt='Organização image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Responsavel</h2>
                <img className='img-skills' src={responsaImage} alt='Responsabilidade image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Equipe</h2>
                <img className='img-skills' src={equipeImage} alt='Trabalho em equipe image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Adaptavel</h2>
                <img className='img-skills' src={adaptoImage} alt='Adaptabilidade image' />
              </li>
              <li className='li-skills'>
                <h2 className='skills-name'>Analítico</h2>
                <img className='img-skills' src={analiImage} alt='Analítico image' />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
