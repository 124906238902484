import React from 'react';
import './index.css';
import homeImage from '../../assets/home.webp';
import linkedinImage from '../../assets/linkedin.png';
import githubImage from '../../assets/github.png';
import instaImage from '../../assets/instagram.png';
import mailImage from '../../assets/gmail.png';

export default function Home() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="home home-resume">
            <div className="resume">
              <h1 className='home-tittle'>Olá, sou Leonardo Roriz</h1>
              <h2 className='home-sub-tittle'>Desenvolvedor Full Stack</h2>
              <p className='home-p'>Estou cursando Analise e desenvolvimento de sistemas na Estácio e também faço um curso de Full Stack na Digital College.</p>
              <ul className='social-links'>
                <li>
                  <a href='https://www.linkedin.com/in/leonardorbessa/'>
                    <img className='img-links' src={linkedinImage} alt='Linkedin' />
                  </a>
                </li>
                <li>
                  <a href='https://github.com/LeonardoRorizBessa'>
                    <img className='img-links' src={githubImage} alt='Github' />
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/leonardororiz/'>
                    <img className='img-links' src={instaImage} alt='Instagram' />
                  </a>
                </li>
                <li>
                  <a href='mailto:leonardo@rorizdev.com.br'>
                    <img className='img-links' src={mailImage} alt='E-mail' />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="home home-code">
            <pre>
              <code>
                {`const `}
                  <span className="keyword">infos</span> = {'{'}
                {`\n  `}
                  <span className="property">frontend</span>: <span className="string">['HTML', 'CSS', 'JS']</span>,
                {`\n  `}
                  <span className="property">backend</span>: <span className="string">['Node', 'Express', 'Python']</span>,
                {`\n  `}
                  <span className="property">libs</span>: <span className="string">['React', 'Bootstrap']</span>,
                {`\n  `}
                  <span className="property">sql</span>: <span className="string">['MySQL']</span>,
                {`\n  `}
                  <span className="property">tools</span>: <span className="string">['Git', 'Github']</span>,
                {`\n  `}
                  <span className="property">languages</span>: <span className="string">['Português', 'Inglês']</span>,
                {`\n}`}
                {`\n\nconsole.log(`}<span
                className="keyword">infos</span>{`);`}
              </code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
}
