import React from 'react'
import './index.css'

export default function About() {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className='about about-tittle'>
            <h1 className='ab-tittle'>About</h1>
          </div>
        </div>
        <div className='col-12'>
          <div className='about div-about'>
            <h2 className='frase-about'>Sou um desenvolvedor Full Stack em formação, com forte compromisso com meu desenvolvimento pessoal e profissional. Além de cursar Análise e Desenvolvimento de Sistemas na Estácio de Sá, também faço um curso focado em desenvolvimento Full Stack na Digital College, enquanto trabalho como motorista de Uber.<br /> Adiquiri minha paixão por tecnologia quando ganhei meu primeiro console 'Super Nintendo'. Desde então, sempre pesquisei sobre esse mundo e acabei me interessando por computadores. Sempre fui muito curioso e pesquisava o que cada peça fazia e como funcionava.<br /> Em 2018.2 comecei a cursar Engenharia da computação na Unifor, após o primeiro semestre tive a oportunidade de fazer um intercambio de 6 meses em Vancouver, Canada. Aprendi inglês atraves de jogos, series e filmes, mas lá pude aprimorar e também conheci muitas outras culturas.<br/> Quando retornei, dei continuidade ao meu curso, porém, era 2020, junto a ele veio a pandemia. Tive que trancar meu curso e começar a trabalhar. Anos depois estou aqui seguindo meu sonho.
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}
