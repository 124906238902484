import React from 'react'
import './index.css'
import loginImage from '../../assets/login.png'
import financeImage from '../../assets/finance.png'
import cepImage from '../../assets/cep.png'
import bankImage from '../../assets/bank.png'
import imcImage from '../../assets/imc.png'
import tourismImage from '../../assets/tourism.png'

export default function Projects() {
  const handleRedirect = (url) => {
    window.location.href = url;
  };

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12'>
          <div className='projects projects-tittle'>
            <h1 className='proj-tittle'>Projetos</h1>
          </div>
        </div>
        <div className='col-12'>
          <div className='projects projects-grid'>
            <ul className="proj-grid">
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/Sistema-de-Login')}>
                <h2 className='projects-name'>Sistema de Login</h2>
                <img className='img-projects' src={loginImage} alt='Login image' />
                <p className='description'>É um sistema de login e cadastro usando React, autenticação é gerenciada com hooks e contextos.</p>
              </li>
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/Controle-Financeiro')}>
                <h2 className='projects-name'>Controle Finaceiro</h2>
                <img className='img-projects' src={financeImage} alt='Financeiro image' />
                <p className='description'>Esse código React implementa um gerenciador de finanças pessoais em um projeto CRUD.</p>
              </li>
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/Buscador-CEP')}>
                <h2 className='projects-name'>Buscador de CEP</h2>
                <img className='img-projects' src={cepImage} alt='CEP image' />
                <p className='description'>Buscador de CEP em React, que consome uma API para fornecer informações de endereços.</p>
              </li>
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/Sistema-de-banco')}>
                <h2 className='projects-name'>Sistema de Banco</h2>
                <img className='img-projects' src={bankImage} alt='Banco image' />
                <p className='description'>Sistema bancário desenvolvido em JavaScript, com funcionalidades como cadastro e outros.</p>
              </li>
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/Calculadora-IMC-com-planos-de-saude')}>
                <h2 className='projects-name'>Calculadora IMC</h2>
                <img className='img-projects' src={imcImage} alt='IMC image' />
                <p className='description'>Calculadora IMC, pega dados passado pelo usuario e decide o melhor plano de saude para ele.</p>
              </li>
              <li className="li-projects" onClick={() => handleRedirect('https://github.com/LeonardoRorizBessa/BessaTurismos')}>
                <h2 className='projects-name'>Bessa Turismos</h2>
                <img className='img-projects' src={tourismImage} alt='Turismo image' />
                <p className='description'>Site personalizado de turismo. Feito apenas com HTML e CSS. Um dos primeiros que fiz.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
