import React from 'react'
import './index.css'
import devImage from '../../assets/dev.png'

export default function Footer() {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className="col-12">
          <div className='footer'>
            <a href='#Header' className='link-dev'>
               <img className='img-dev' src={devImage} alt='Dev image' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
