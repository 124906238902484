import './Styles/styles.css'
import Header from './components/Header'
import Home from './components/Home'
import Projects from './components/Projects'
import Skills from './components/Skills'
import About from './components/About'
import Footer from './components/Footer'
import 'bootstrap/dist/css/bootstrap.min.css'


function App() {
  return (
    <div>
      <Header />
      <Home />
      <Projects />
      <Skills />
      <About />
      <Footer />
    </div>
  );
}

export default App;