import React, { useState } from 'react';
import './index.css';
import devImage from '../../assets/dev.png';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3">
          <div className="header header-logo">
            <a href='#Header'>
              <img className='img-header' src={devImage} alt="Dev imagem" />
            </a>
          </div>
        </div>
        <div className="col-9">
          <div className="header header-menu">
            <button id="menu-button" onClick={toggleMenu}>
              <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <nav className={`menu ${isMenuOpen ? 'open' : ''}`}>
              <ul className="ul-header">
                <li className='li-header'>
                  <a href='#Home' className='links-nav'>Home</a>
                </li>
                <li className='li-header'>
                  <a href='#Projects' className='links-nav'>Projects</a>
                </li>
                <li className='li-header'>
                  <a href='#Skills' className='links-nav'>Skills</a>
                </li>
                <li className='li-header'>
                  <a href='#About' className='links-nav'>About</a>
                </li>
                <li className='li-header'>
                  <a href='#Contact' className='links-nav'>Contact</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}